import gsap from "gsap";

// Hide the menu initially
const menu = document.getElementById("menu");
menu.style.display = "none";

document.getElementById("nav-menu-button").addEventListener("click", openFullScreenMenu);
document.getElementById("close-menu-button").addEventListener("click", closeFullScreenMenu);

// Add event listeners to all .nav-link elements
const navLinks = document.querySelectorAll(".nav-link");
navLinks.forEach(link => {
  link.addEventListener("click", closeFullScreenMenuDelay);
});
const menuIllo = document.querySelector(".menu-illo");

function openFullScreenMenu() {
  // Code to open the full screen menu goes here
  // Use gsap to animate the menu in from the top
  var tl = gsap.timeline();
  menu.style.display = "flex";
  tl.fromTo(menu, { y: "-100%" }, { y: 0, duration: 0.5, ease: "power2.out"});
  tl.fromTo(navLinks, { x: "-100%", opacity: 0 }, { x: 0, opacity: 1, duration: 0.5, ease: "power2.out", stagger: 0.1 });
  //menu illo from right
  tl.fromTo(menuIllo, { x: "100%", opacity: 0 }, { x: 0, opacity: 1, duration: 0.5, ease: "power2.out"});
}

function closeFullScreenMenu() {
  // Code to close the full screen menu goes here
  // Use gsap to animate the menu out to the top
  gsap.to(menu, { y: "-100%", duration: 0.5, ease: "power2.out", onComplete: () => {
    menu.style.display = "none";
  } });
}
function closeFullScreenMenuDelay() {
  // Code to close the full screen menu goes here
  // Use gsap to animate the menu out to the top
  gsap.to(menu, { y: "-100%", duration: 0.5, delay: 0.2, ease: "power2.out", onComplete: () => {
    menu.style.display = "none";
  } });
}