"use strict";
import gsap from "gsap";
import { Flip } from "gsap/Flip";
import SplitType from 'split-type';
import Warp from 'warpjs';
import "/hamburger-menu.js";

window.Webflow ||= [];
window.Webflow.push(() => {
	// gsap hero animation, #right-panel and #left-panel slide in from the sides
  function animateDesktopPanels() {
    gsap.from("#right-panel", { x: 2000, duration: 2, ease: "power2.out" });
    gsap.from("#left-panel", { x: -2000, duration: 2, ease: "power2.out" });
  }
  // gsap hero animation, #right-panel and #left-panel slide in from the sides
  if (window.innerWidth >= 1024) {
    animateDesktopPanels();
  } else {
    gsap.from(".right-hand", { x: 1000, duration: 2, ease: "power2.out" });
    gsap.from(".left-hand", { x: -1000, duration: 2, ease: "power2.out" });

  }
  gsap.from(".sun-svg", { top: '-100%', duration: 2, ease: "power2.out" });
  gsap.from("[fadeIn]", { opacity: 0, duration: 1, ease: "power2.out"})
  gsap.from("[fadeInUp]", { y: '100%', opacity: 0, duration: 1, ease: "power2.out"})

  // SplitType for hero text
  // Create spans
let typeSplit = new SplitType('[split]', {
  types: 'words, chars',
  tagName: 'span',
})
// Onload grow stagger char
gsap.from($('[anim="text"]').find('.word'), {
  opacity: 0,
  y: '100%',
  delay: 1,
  duration: 0.3,
  ease: 'power1.out',
  stagger: { each: 0.1, from: 'start' },
})
// Fade in Animation
gsap.from($('[anim="fadeIn"]'), {
  opacity: 0,
  y: '100%',
  delay: 0.5,
  duration: 0.3,
  ease: 'power1.out',
  stagger: { each: 0.1, from: 'start' },
})

// menu open and stagger
// when #menu-button is clicked, the menu opens and the menu items stagger in
// document.getElementById("menu-button").addEventListener("click", function () {
//   gsap.to("#menu", { x: 0, duration: 0.5, ease: "power2.out" });
//   gsap.from("#menu li", { x: -100, opacity: 0, duration: 0.5, ease: "power2.out", stagger: 0.1 });
// });

// Keynote Animation using GSAP
const keynoteCards = document.querySelectorAll('.keynote-card-wrapper');

keynoteCards.forEach((card, index) => {
  card.addEventListener('mouseenter', () => {
    const summary = card.querySelector('.keynote-summary');
    const otherCards = Array.from(card.parentNode.children).filter((c, i) => i !== index);
    const frame = card.querySelector('.headshot-shape');

    gsap.to(summary, { x: '100%', opacity: 1, duration: 0.5, ease: 'power2.out' });
    gsap.to(frame, { scale: 1.1, duration: 0.5, ease: 'power2.out' });
    if (index !== keynoteCards.length - 1) {
      gsap.to(otherCards, { x: summary.offsetWidth, duration: 0.5, ease: 'power2.out' });
    }
  });

  card.addEventListener('mouseleave', () => {
    const summary = card.querySelector('.keynote-summary');
    const otherCards = Array.from(card.parentNode.children).filter((c, i) => i !== index);
    const frame = card.querySelector('.headshot-shape');

    gsap.to(summary, { x: 0, opacity: 0, duration: 0.5, ease: 'power2.out' });
    gsap.to(frame, { scale: 2, duration: 0.5, ease: 'power2.out' });
    if (index !== keynoteCards.length - 1) {
      gsap.to(otherCards, { x: 0, duration: 0.5, ease: 'power2.out' });
    }
  });
});



// end of Webflow.push
});
function initializeHoverEffects() {
  const elements = document.querySelectorAll('[effect="warp"]')

  elements.forEach(element => {
    const warp = new Warp(element)
    warp.interpolate(8)
    warp.transform(([ x, y ]) => [ x, y, y ])

    let offset = 0
    let animationId = null
    let isHovering = false
    let transitionProgress = 0

    function easeOutQuad(t) {
      return t * (2 - t)
    }

    function animate(timestamp) {
      if (isHovering) {
        transitionProgress = Math.min(1, transitionProgress + 0.15)  // Increased for faster transition
      } else {
        transitionProgress = Math.max(0, transitionProgress - 0.15)  // Increased for faster transition
      }

      const easedProgress = easeOutQuad(transitionProgress)

      warp.transform(([ x, y, oy ]) => [
        x,
        oy + easedProgress * (100 * Math.sin(x / 16 + offset)),
        oy
      ])

      if (isHovering) {
        offset += 0.1
      }

      if (transitionProgress > 0 || isHovering) {
        animationId = requestAnimationFrame(animate)
      } else {
        animationId = null
      }
    }

    function startAnimation() {
      isHovering = true
      if (!animationId) {
        animationId = requestAnimationFrame(animate)
      }
    }

    function stopAnimation() {
      isHovering = false
    }

    const warpContainers = document.querySelectorAll('[warp-container]')
    warpContainers.forEach(warpContainer => {
      warpContainer.addEventListener('mouseover', startAnimation)
    })
    warpContainers.forEach(warpContainer => {
      warpContainer.addEventListener('mouseout', stopAnimation)
    })
  })
}

// Call this function when the DOM is ready
document.addEventListener('DOMContentLoaded', initializeHoverEffects)